<template>
	<section>
		<div class="dashboard-charts">
			<el-card class="tab-card-cash-flow">
				<CashFlow />
			</el-card>
			<timeline class="timeline" />
			<el-card class="tab-card">
				<el-tabs v-model="activeTab">
					<el-tab-pane
						label="Crédito"
						name="wallets">
						<div class="chart-wrapper">
							<credit-wallet-chart :loading="loadingWallets" />
							<client-wallet-chart :loading="loadingWallets" />
						</div>
					</el-tab-pane>
					<el-tab-pane
						label="Histórico de vendas"
						name="sale-history">
						<sale-history-chart
							:period="period"
							:loading="loadingHistory" />
					</el-tab-pane>
					<el-tab-pane
						label="Histórico de recebimentos"
						name="payment-history">
						<charge-history-chart
							:loading="loadingHistory"
							:period="period"/>
					</el-tab-pane>
				</el-tabs>
			</el-card>
		</div>
		<!-- <div class="dashboard-charts__income">
			<dashboard-income
				:income="income"
				:loading="loadingWallets"
			/>
		</div> -->
	</section>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";

import CreditWalletChart from "@/components/Charts/CreditWalletChart";
import ClientWalletChart from "@/components/Charts/ClientWalletChart";
import SaleHistoryChart from "@/components/Charts/SaleHistoryChart";
import ChargeHistoryChart from "@/components/Charts/ChargeHistoryChart";
import CashFlow from "@/containers/Dashboard/CashFlow";
import Timeline from "@/components/Dashboard/Timeline";

export default {
	components: {
		CreditWalletChart,
		ClientWalletChart,
		SaleHistoryChart,
		ChargeHistoryChart,
		CashFlow,
		Timeline
	},
	props: {
		period: {
			type: String,
			default: "week"
		}
	},
	data() {
		return {
			loadingWallets: true,
			loadingHistory: true,
			activeTab: "wallets"
		};
	},
	computed: mapGetters({
		income: types.DASHBOARD_INCOME
	}),
	async created() {
		try{
		await this.$store.dispatch(types.DASHBOARD_CHARTS);
		this.loadingWallets = false;
		await this.$store.dispatch(types.DASHBOARD_SALE_HISTORY);
		this.loadingHistory = false;
		await this.$store.dispatch(types.CHARGE_HISTORY);
		} catch(error) {
			if (this.$raven && error.status === 500) {
				this.$raven.captureException(error.message, { req: error });
			}
			this.$notify.error({ title: error.message });
		}
	}
};
</script>
<style lang="scss" scoped>
.tab-card {
	grid-area: charts;
	@media screen and (max-width: 425px) {
		height: auto;
	}
}
.tab-card-cash-flow {
	grid-area: cashflow;
	@media screen and (max-width: 425px) {
		height: auto;
	}
}
.chart-wrapper {
	display: grid;
	grid-template-columns: repeat(2, 50%);
	@media screen and (max-width: 768px) {
		grid-template-columns: 100%;
	}
}
.dashboard-charts {
	display: grid;
	grid-template-areas:
	"cashflow timeline"
	"charts timeline";
	grid-column-gap: 10px;
	grid-row-gap: 20px;
	margin-bottom: 1rem;
	@media screen and (max-width: 768px) {
		grid-template-areas:
	"cashflow"
	"charts"
	"timeline";
	}
}
.timeline {
	grid-area: timeline;
}
.dashboard-charts__income {
	margin-bottom: 1rem;
}
</style>
