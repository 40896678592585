<template>
  <section class="credit-parameter">
    <div class="card">
      <el-card>
        <h2>Política de Risco</h2>
        <h3>Taxa de aprovação</h3>

        <range-picker
          v-model="risk"
          :min="1"
          :max="9"
          :step="1"
          :labels="{
            bottom: {
              min: 'aprovar menos',
              max: 'aprovar mais'
            }
          }"
        />

        <h3>Score MÍNIMO: <strong>{{ updatedScoreLimit }}</strong></h3>
        <br>
        <el-button v-if="storesWithFeature.includes(this.storeID)" :disabled="!currentStore.permissions.settings" size="mini" type="success" class="button" @click="updateScoreLimit">Aplicar</el-button>
        <el-tooltip v-if="!storesWithFeature.includes(this.storeID)"
          class="header-card"
          effect="dark"
          content="Fale com nossa equipe para contratar essa funcionalidade."
          placement="bottom"
        >
            <font-awesome-icon
              v-if="!storesWithFeature.includes(this.storeID)"
              icon="lock"
              size="lg"
              style="color: #FF5E44; margin-left: 14px; cursor: pointer;"
            />
        </el-tooltip>
      </el-card>
    </div>

    <div class="card">
      <el-card>
        <h2>Política de Crédito</h2>
        <h3>Faixa de limite de crédito</h3>
        <br/>
        <h3>Limite de crédito MÍNIMO atual: <strong>{{ minCredit }}</strong></h3>
        <h3>Limite de crédito MÁXIMO atual: <strong>{{ maxCredit }}</strong></h3>

        <range-picker
          v-model="credit"
          :min="0"
          :max="15000"
          :step="100"
          :config="{ innerRangeEnabled: true }"
          :labels="{
            bottom: {
              min: '',
              max: ''
            },
            top: {
              min: minCredit,
              max: maxCredit
            }
          }"
        />

        <el-button icon="lock" v-if="storesWithFeature.includes(this.storeID)" :disabled="!currentStore.permissions.settings" size="mini" type="success" @click="updateCreditLimits">Aplicar</el-button>
        <el-tooltip v-if="!storesWithFeature.includes(this.storeID)"
          class="header-card"
          effect="dark"
          content="Fale com nossa equipe para contratar essa funcionalidade."
          placement="bottom"
        >
            <font-awesome-icon
              v-if="!storesWithFeature.includes(this.storeID)"
              icon="lock"
              size="lg"
              style="color: #FF5E44; margin-left: 14px; cursor: pointer;"
            />
        </el-tooltip>

      </el-card>
    </div>
  </section>
</template>

<script>
import RangePicker from "@/components/RangePicker";
import * as types from "@/store/types";
import { mapGetters } from "vuex";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCog,
  faUser,
  faBars,
  faQuestionCircle,
  faSignOutAlt,
  faWindowClose,
  faLock
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faCog,
  faUser,
  faBars,
  faQuestionCircle,
  faSignOutAlt,
  faWindowClose,
  faLock
);
export default {
  name: 'CreditParameter',
  components: {
    RangePicker,
    FontAwesomeIcon
  },
  data() {
    return {
      risk: 5000,
      credit: {
        min: 0,
        max: 15000
      },
      riskList: [
        {index: 1, value: 60000},
        {index: 2, value: 50000},
        {index: 3, value: 40000},
        {index: 4, value: 30000},
        {index: 5, value: 25000},
        {index: 6, value: 20000},
        {index: 7, value: 15000},
        {index: 8, value: 9500},
        {index: 9, value: 5000}
      ],
      storesWithFeature: [
        "310bfe3d-5aa3-4941-b1e2-0e5956abc138", // Mocco Center
        "5c6ccc77-a7a8-4255-9fae-467a5098494b", // FARMÁCIA SÃO JOSÉ DE BILAC LTDA ME
        "0439b7f6-37a5-4c5d-acd7-b674e0e0d760", // Stilo Kids
        "5c28787d-d329-40e3-966d-22017d01f8b4", // Central Cred
        "cf644fce-2f2f-4a96-9539-e4e8640ce958", // FARMÁCIA DIA A DIA
        "3434b1a3-51ea-4dc9-89f7-92692d168b11", // MCarvalho Store
        "c9c91877-86fe-4abb-8be4-bca58df91409", // CREDIARIO FASHION
        "32da25a1-bace-4e3f-bbb9-539040584293", // Magazine Couto
        "2a7fdffa-d4a8-4fdb-94af-56fe3e53a814", // Suplerex
        "16ad29b0-99b5-48f1-9a1d-f44df8b50626", // AJN Mercearia e Padaria
        "d0e8cb6e-359c-4a6c-be03-58798c531042", // Cartåo Rede Mercado
        "ea4fdce9-7976-4a59-83bd-0c7e9379d646", // Fenix Surf
        "edc5f5a5-e852-47fd-ba9c-b12b82dc403c", // Reoboe Variedades
        "37626710-ced5-4400-af04-8c989caa2bfe", // neopag store
        "8fc81126-b0d7-4d8a-860b-b966b9022ba2", // BIGAZINE
        "c3056c6c-1d1d-421f-8790-a58e1f91a320", // LEADS CARE
        "ebd0ef45-245a-4177-b6b3-2de726c5c38f"  // ODES CRED
      ]
    }
  },
  watch: {
    /* risk() {
      console.log('risk picker changed', this.riskValue)
    },
    credit() {
      console.log('credit picker changed', this.credit)
    }, */
    score_limit() {
      this.updateRisk;
    },
    credit_limits() {
      this.updateCredit;
    }
  },
  computed: {
    ...mapGetters({
			score_limit: types.STORE_SCORE_LIMIT,
			credit_limits: types.STORE_CREDIT_LIMITS,
      storeID: types.AUTH_ACTIVE_STORE,
      currentStore: types.AUTH_ACTIVE_STORE_DETAILS
		}),
    minCredit() {
      return `R$ ${this.credit.min}`
    },
    maxCredit() {
      return `R$ ${this.credit.max}`;
    },
    updatedScoreLimit(){

      if (this.risk == 9) {
        return `5.000 pts`;
      } else if (this.risk == 8) {
        return `9.500 pts`;
      } else if (this.risk == 7) {
        return `15.000 pts`;
      } else if (this.risk == 6) {
        return `20.000 pts`;
      } else if (this.risk == 5) {
        return `25.000 pts`;
      } else if (this.risk == 4) {
        return `30.000 pts`;
      } else if (this.risk == 3) {
        return `40.000 pts`;
      } else if (this.risk == 2) {
        return `50.000 pts`;
      } else if (this.risk == 1) {
        return `60.000 pts`;
      } else {
        return `${this.risk} pts`;
      }

    },
    updateCredit() {
      this.credit = this.credit_limits;
      //return this.credit;
    },
    updateRisk() {
      this.risk = this.riskIndex;
      //return this.risk;
    },
    riskIndex() {
      return this.riskList.filter(risk => risk.value == this.score_limit)[0].index;
    },
    riskValue() {
      return this.riskList.filter(risk => risk.index == this.risk)[0].value;
    }
  },
  async created() {
      await this.$store.dispatch(types.STORE_SCORE_LIMIT, this.storeID);
      await this.$store.dispatch(types.STORE_CREDIT_LIMITS, this.storeID);
	},
  methods: {
    async updateScoreLimit() {
			try {
				const payload = {
					score_limit: {
						value: this.riskValue
					}
				};
				await this.$store.dispatch(types.UPDATE_STORE_SCORE_LIMIT, {
					storeID: this.storeID,
					payload
				});
				this.$notify.success({ title: "O motor de crédito foi atualizado com sucesso" });
			} catch (error) {
				if (this.$raven && error.status === 500) {
					this.$raven.captureException(error.message, { req: error });
				}
				this.$notify.error({ title: error.message });
			}
		},
    async updateCreditLimits() {
			try {
				const payload = {
					credit_limits: {
						max_credit_limit: this.credit.max,
            min_credit_limit: this.credit.min
					}
				};
				await this.$store.dispatch(types.UPDATE_STORE_CREDIT_LIMITS, {
					storeID: this.storeID,
					payload
				});
				this.$notify.success({ title: "O motor de crédito foi atualizado com sucesso" });
			} catch (error) {
				if (this.$raven && error.status === 500) {
					this.$raven.captureException(error.message, { req: error });
				}
				this.$notify.error({ title: error.message });
			}
		}
  }
}
</script>

<style lang="scss" scoped>
.credit-parameter {
  height: 400px;
  display: flex;
  flex-direction: row;
  .card {
    flex: 1;
    .range-picker-container {
      padding: 0 10px;
    }
    &:first-child {
      margin-right: 5px;
    }
    &:last-child {
      margin-left: 5px;
    }
    h1 {
      font-weight: 600;
      font-size: 28px;
    }
    h2 {
      font-size: 20px;
      font-weight: 400;
    }
    h3 {
      font-size: 14px;
      font-weight: 400;
      color: #34495e;
      font-family: "Montserrat", sans-serif;
    }
    h1, h2, h3 {
      margin: 0;
      padding: 0;
      color: #6c737c;
      font-family: "Montserrat", sans-serif;
    }
    .range-picker-container {
      margin: 40px 0;
    }
  }
}
</style>
