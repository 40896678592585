<template>
  <section v-loading="loading" v-if="!hide" class="getting-started-section">
    <h3>Por onde começar?</h3>
    <section class="steps-wrapper">
      <div
        v-for="step in steps"
        :key="step.id"
        :class="{ completed: step.completed, active: step.active }"
      >
        <div v-if="!step.completed" class="step-marker" />
        <img v-else alt="tick-icon" class="step-icon" src="../../assets/svg/tick.svg" />
        <p @click="handleClick(step.route)">{{ step.label }}</p>
      </div>
    </section>
  </section>
</template>

<script>
import * as types from "@/store/types";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      steps: [
        {
          id: "used_credit_planner",
          label: "Configurar planos",
          route: "/configuracoes/planos",
          active: false,
          completed: false
        },
        {
          id: "has_card",
          label: "Personalizar cartão",
          route: "/cartao-neopag",
          active: false,
          completed: false
        },
        {
          id: "queried_users",
          label: "Consultar cliente",
          route: "/consulta-neopag",
          active: false,
          completed: false
        },
        {
          id: "has_sales",
          label: "Venda",
          route: "/venda",
          active: false,
          completed: false
        },
        {
          id: "has_payments",
          label: "Recebimento",
          route: "/recebimento",
          active: false,
          completed: false
        }
      ],
      loading: false,
      hide: true
    };
  },
  computed: {
    ...mapGetters({
      authOrigin: types.AUTH_ORIGIN,
      storeID: types.AUTH_ACTIVE_STORE
    })
  },
  async mounted() {
    this.loading = true;
    const status = await this.$store
      .dispatch(types.STORE_GETTING_STARTED, this.storeID)
      .catch(error => {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      });
    const steps = this.steps.map(item => ({
      ...item,
      completed: status ? status[item.id] : false
    }));
    if (
      !steps[0].completed ||
      !steps[1].completed ||
      !steps[2].completed ||
      !steps[3].completed ||
      !steps[4].completed
    ) {
      this.hide = false;
    }
    if (!steps[0].completed) {
      steps[0].active = true;
    } else if (!steps[1].completed) {
      steps[1].active = true;
    } else if (!steps[2].completed) {
      steps[2].active = true;
    } else if (!steps[3].completed) {
      steps[3].active = true;
    } else if (!steps[4].completed) {
      steps[4].active = true;
    }
    this.steps = steps;
    this.loading = false;
  },
  methods: {
    handleClick(route) {
      if (this.authOrigin === "app") {
        return NativeAppChannel.postMessage(`route&${route}`);
      }
      this.$router.push(route);
    }
  }
};
</script>

<style lang="scss" scoped>
.getting-started-section {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  align-items: center;
  h3 {
    font-family: "Montserrat";
    color: $preto;
    letter-spacing: 1.125px;
  }
  .steps-wrapper {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    .active {
      .step-marker {
        &:before {
          display: block;
          content: " ";
          width: 14px;
          height: 14px;
          background: $laranja;
          border-radius: 7px;
        }
        animation: pulse 2s infinite;
        &:hover {
          animation: none;
        }
      }
    }
    > div {
      .step-icon {
        width: 20px;
        height: 20px;
      }
      .step-marker {
        width: 20px;
        height: 20px;
        background: transparent;
        border: 2px solid $laranja;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      p {
        font-family: "Montserrat";
        color: $preto;
        letter-spacing: 1.125px;
        font-size: 14px;
        padding-top: 6px;
        &:hover {
          cursor: pointer;
        }
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      position: relative;

      &:after {
        position: absolute;
        right: -44.6%;
        top: 9px;
        width: 90%;
        display: block;
        content: " ";
        height: 2px;
        background: $preto;
        opacity: 0.3;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    overflow: hidden;
    @include box-shadow(1);
    margin-bottom: 16px;
    border-radius: 5px;
    background: #fff;
    h3 {
      margin-bottom: 22px;
    }
    .steps-wrapper {
      grid-template-columns: 1fr;
      > div {
        &:after {
          left: 8px;
          top: 40px;
          width: 2px;
          height: 48px;
        }
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 22px 0;
        p {
          padding: 0 0 0 8px;
        }
      }
    }
  }
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 94, 68, 0.4);
  }
  70% {
    box-shadow: 0 0 0 6px rgba(255, 94, 68, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 94, 68, 0);
  }
}
</style>
