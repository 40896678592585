<template>
	<dashboard />
</template>

<script>
import Dashboard from "@/containers/Dashboard/Dashboard";

export default {
	components: {
		Dashboard
	}
};
</script>
