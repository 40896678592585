<template>
  <definition-cards
    v-if="definitions"
    :loading="!definitions"
    :definitions="definitions"
    :activeStore="active_store"
    @period="handlePeriod"
  />
</template>

<script>
import * as types from "@/store/types";
import { mapGetters } from "vuex";
import definitionCards from "@/components/Dashboard/Cards";
export default {
  components: {
    definitionCards
  },
  data() {
    return {
      loading: true
    };
  },
  computed: mapGetters({
    definitions: types.DASHBOARD_DEFINITIONS,
    active_store: types.AUTH_ACTIVE_STORE,
  }),
  methods: {
    handlePeriod(value) {
      this.$emit("period", value);
    }
  }
};
</script>
