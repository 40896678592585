<template>
  <div class="definitions">
    <el-card>
      <section class="radio-card">
        <span class="title">Período</span>
        <el-select v-model="period" loading-text="Carregando...">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </section>
    </el-card>
    <el-card v-for="definition in keys" :key="definition" >
      <template v-if="loading" >
        <span
          :class="{ '--diff': definition !== 'active_customers' && definition !== 'new_customers' && definition !== 'sales_number'}"
          class="title"
        >{{ titles[definition] }}</span>
        <div class="value">
          <span
            v-fit
            v-if="definition === 'approval_rate' || definition === 'default_rate' || definition === 'credit_limit_per_client' || definition === 'active_customers' || definition === 'new_customers' || definition === 'sales_number'"
          >
            <i class="el-icon el-icon-loading" />
          </span>
          <span v-else>
            <i class="el-icon el-icon-loading" />
          </span>
        </div>
        <div>&nbsp;</div>
      </template>
      <template v-else>
        <span
          :class="{ '--diff':  definition !== 'active_customers' && definition !== 'new_customers' && definition !== 'sales_number' }"
          class="title"
        >{{ titles[definition] }}</span>
        <div class="value">
          <span
            v-fit
            v-if="definition === 'approval_rate' || definition === 'default_rate' || definition === 'active_customers' || definition === 'new_customers' || definition === 'sales_number'"
          >
          {{ (definition == 'approval_rate' || definition === 'default_rate' ) ? showAsPercent(definitions[`current_${period}`][definition]) : definitions[`current_${period}`][definition] }} </span>
          <span v-else>{{ $n(definitions[`current_${period}`][definition] || 0, 'currency') }}</span>
        </div>
        <div :class="getChangeClass(period, definition)">
          (
          <i :class="getIconClass(period, definition)" class="el-icon" />
          {{ comparative[period][definition] }}% )
        </div>
      </template>
    </el-card>
  </div>
</template>

<script>
const getPercentageChange = (oldNumber, newNumber) => {
  const decreaseValue = newNumber - oldNumber;
  const change = ((decreaseValue / (oldNumber || 1)) * 100).toFixed(1);
  return isFinite(change) ? +change : 0;
};
const storesWithNewFields = [
  "310bfe3d-5aa3-4941-b1e2-0e5956abc138", // Mocco Center
  "5c6ccc77-a7a8-4255-9fae-467a5098494b", // FARMÁCIA SÃO JOSÉ DE BILAC LTDA ME
  "0439b7f6-37a5-4c5d-acd7-b674e0e0d760", // Stilo Kids
  "5c28787d-d329-40e3-966d-22017d01f8b4", // Central Cred
  "cf644fce-2f2f-4a96-9539-e4e8640ce958", // FARMÁCIA DIA A DIA
  "3434b1a3-51ea-4dc9-89f7-92692d168b11", // MCarvalho Store
  "c9c91877-86fe-4abb-8be4-bca58df91409", // CREDIARIO FASHION
  "32da25a1-bace-4e3f-bbb9-539040584293", // Magazine Couto
  "2a7fdffa-d4a8-4fdb-94af-56fe3e53a814", // Suplerex
  "16ad29b0-99b5-48f1-9a1d-f44df8b50626", // AJN Mercearia e Padaria
  "d0e8cb6e-359c-4a6c-be03-58798c531042", // Cartåo Rede Mercado
  "ea4fdce9-7976-4a59-83bd-0c7e9379d646", // Fenix Surf
  "edc5f5a5-e852-47fd-ba9c-b12b82dc403c", // Reoboe Variedades
  "37626710-ced5-4400-af04-8c989caa2bfe"  // neopag store
]
const getKeys = (showConditionalFields = false) => [
  ...(showConditionalFields ?
    [
      "approval_rate",
      "default_rate",
      "credit_limit_per_client",
    ] :
    [ "active_customers",
      "new_customers",
      "sales_number"
    ]
  ),
  "average_sale",
  "total_sales"
];
export default {
  props: {
    definitions: {
      type: Object,
      required: true
    },
    activeStore: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      showPast: false,
      keys: getKeys(storesWithNewFields.includes(this.activeStore)),
      period: "week",
      options: [
        {
          value: "year",
          label: "Anual"
        },
        {
          value: "month",
          label: "Mensal"
        },
        {
          value: "week",
          label: "Semanal"
        },
        {
          value: "day",
          label: "Diário"
        }
      ],
      titles: {
        active_customers: "clientes ativos",
        new_customers: "novos clientes",
        total_sales: "total de vendas",
        average_sale: "ticket médio",
        sales_number: "vendas",
        approval_rate: "Taxa de aprovação",
        default_rate: "Inadimplência Geral",
        credit_limit_per_client: "Limite de crédito médio por cliente"
      }
    };
  },
  computed: {
    comparative() {
      return ["year", "month", "week", "day"].reduce((comp, period) => {
        comp[period] = {};
        this.keys.forEach(it => {
          comp[period][it] = getPercentageChange(
            this.definitions[`last_${period}`][it],
            this.definitions[`current_${period}`][it]
          );
        });
        return comp;
      }, {});
    }
  },
  watch: {
    period: function(newValue) {
      this.$emit("period", newValue);
    }
  },
  methods: {
    getChangeClass(p, d) {
      return {
        change: true,
        "change--positive": this.comparative[p][d] > 0,
        "change--negative": this.comparative[p][d] < 0
      };
    },
    getIconClass(p, d) {
      return {
        "el-icon-caret-top": this.comparative[p][d] > 0,
        "el-icon-caret-bottom": this.comparative[p][d] < 0,
        "el-icon-sort": this.comparative[p][d] === 0
      };
    },
    showAsPercent(text) {
      if(!text || ~~text < 0) return " -- %";
      return `${text.toString().replace(".",",")}%`;
    }
  }
};
</script>

<style lang="scss" scoped>
.radio-card {
  text-align: center;
  .title {
    min-height: 42px;
    font-family: $title-font;
    font-size: 13px;
    line-height: 42px;
    text-transform: uppercase;
    opacity: 0.75;
    &.--diff {
    }
  }
  .radio-group {
    padding-top: 0.5rem;
    text-align: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;
    .el-radio.is-bordered {
      width: 100%;
      margin: 0;
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(2, 45%);
      .el-radio.is-bordered {
        padding: 12px 20px 0 4px;
        .el-radio__label {
          font-size: 13px;
          padding-left: 2px;
        }
      }
    }
  }
}
.definitions {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  .el-card {
    @include medium-up {
      flex: 1;
      margin-left: 1rem;
      &:first-of-type {
        margin-left: 0;
      }
    }
    @extend %el-card-elements;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 425px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
%el-card-elements {
  margin: 0;
  .el-card__body {
    padding: 1rem;
  }
  .title,
  .value,
  .change {
    display: inline-block;
    width: 100%;
    color: $preto;
    text-align: center;
  }
  .title {
    font-family: $title-font;
    font-size: 13px;
    text-transform: uppercase;
    opacity: 0.75;
    @media screen and (max-width: 1024px) {
      font-size: 10px;
    }
    &.--diff {
    }
  }
  .value {
    padding: 0.5rem 0;
    font-family: $base-font;
    font-weight: bold;
    span {
      font-size: 25px !important;
    }
  }
  .fit {
    white-space: nowrap;
  }
  .change {
    .el-icon {
      font-size: 14px;
    }
    &--positive .el-icon {
      color: green;
    }
    &--negative .el-icon {
      color: red;
    }
  }
}
</style>
