<template>
  <div v-loading="loading">
    <h3 class="title">Nível de score dos clientes</h3>
    <v-chart ref="chart" :options="options" :auto-resize="true" @click="onClick" />
    <el-dialog
      :visible.sync="showModal"
      title="Clientes"
      custom-class="chart_dialog"
      :fullscreen="$isMobile"
      :width="$isMobile ? '100%' : '70%'"
    >
      <el-table
        :data="selectedUsers"
        :default-sort="{
          prop: 'current_remaining_amount',
          order: 'descending'
        }"
        @row-click="onTableClick"
      >
        <el-table-column min-width="230" prop="user_name" label="Nome" align="center" sortable />
        <el-table-column
          align="center"
          min-width="150"
          :formatter="
            date =>
              date && date.last_closed_statement_date
                ? formatDate(date.last_closed_statement_date)
                : ''
          "
          prop="last_closed_statement_date"
          label="Última fatura fechada"
          sortable
        />
        <el-table-column
          min-width="150"
          align="center"
          :formatter="
            (row, column) => $n(row.current_remaining_amount, 'currency')
          "
          prop="current_remaining_amount"
          label="Valor da última fatura"
          sortable
        />
        <el-table-column
          min-width="100"
          align="center"
          prop="days_late"
          label="Dias em atraso"
          sortable
        />
        <el-table-column min-width="100" align="center" prop="score" label="Score" sortable />
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import ECharts from "vue-echarts/components/ECharts";
import "echarts/lib/chart/pie";
import "echarts/lib/component/legendScroll";
import "echarts/lib/component/tooltip";
import moment from "moment";

export default {
  components: {
    "v-chart": ECharts
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      options: {},
      selectedUsers: [],
      showModal: false
    };
  },
  computed: mapGetters({
    authOrigin: types.AUTH_ORIGIN,
    clientWallet: types.DASHBOARD_CLIENT_WALLET
  }),
  watch: {
    clientWallet: function(newValue) {
      let dados = [];
      newValue.forEach(element => {
        dados = dados.concat(element.store_customers);
      });

      let grupo = {};
      dados.forEach(element => {
        if (grupo[element.score_level]) {
          return grupo[element.score_level].store_customers.push(element);
        }
        grupo = {
          ...grupo,
          [element.score_level]: {
            name: element.score_level,
            order:
              element.score_level === "Alto"
                ? 1
                : element.score_level === "Médio"
                ? 2
                : 3,
            store_customers: [element]
          }
        };
      });

      let orderedGrupo = Object.values(grupo)
        .map(item => ({
          value: item.store_customers.length,
          name: item.name,
          store_customers: item.store_customers,
          order: item.order
        }))
        .sort((a, b) => {
          if (a.order > b.order) {
            return 1;
          } else {
            return -1;
          }
        });

      this.options = {
        tooltip: {
          trigger: "item",
          formatter: function(params) {
            return `${params.data.name}: ${params.data.value} (${params.percent}%)`;
          }
        },
        color: [
          "rgba(91,166,7,0.8)", //Bom
          "rgba(251,211,112, 0.8)", //Médio
          "rgba(243,139,141, 0.8)" //
        ],
        legend: {
          type: "scroll",
          orient: "horizontal",
          bottom: "bottom",
          data: orderedGrupo.map(item => item.name)
        },
        series: [
          {
            name: "Carteira de crédito",
            type: "pie",
            label: {
              show: false
            },
            data: orderedGrupo,
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          }
        ]
      };
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    onClick(data) {
      this.selectedUsers = data.data.store_customers;
      this.showModal = true;
    },
    onTableClick(row) {
      if (this.authOrigin === "app") {
        return NativeAppChannel.postMessage(client.user.id);
      }
      this.$router.push({
        name: "Perfil - Histórico de lançamentos",
        params: { clientID: row.user_id }
      });
    }
  }
};
</script>

<style lang="scss">
.echarts {
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 1300px) {
  }
}
.title {
  text-align: center;
}
.el-table {
  cursor: pointer;
}
.chart_dialog {
  .el-dialog__body {
    max-height: 60vh;
    overflow: auto;
  }
}
.empty-state {
  text-align: center;
  margin-top: 20px;
  font-family: "Montserrat";
  color: $preto;
}
</style>
