<template>
    <section>
      <v-chart ref="chart" :options="options" :auto-resize="true" @click="goToStatements" />
  </section>
</template>

<script>
import * as types from "@/store/types";
import { mapGetters } from "vuex";
import ECharts from "vue-echarts/components/ECharts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/legendScroll";
import "echarts/lib/component/tooltip";
export default {
  components: {
    "v-chart": ECharts
  },
  data() {
    return {
      options: {},
    };
  },
  watch: {
    storeCashFlow: function(val) {
      const dates = val.map(item => Object.keys(item)[0]);
      const paid_amount = val.map(item => Object.values(item)[0].paid_amount);
      const remaining = val.map(item => Object.values(item)[0].remaining);
      const total = val.map(item => Object.values(item)[0].total);
      this.options = {
        legend: {},
        tooltip: {},
        legend: {
          type: "scroll",
          orient: "horizontal",
          bottom: "bottom"
        },
        dataset: {
          source: [
            ["dates", ...dates],
            ["Total", ...total],
            ["Pago", ...paid_amount],
            ["Em aberto", ...remaining],
          ]
        },
        xAxis: [{ type: "category", gridIndex: 0 }],
        yAxis: [{ gridIndex: 0 }],
        grid: [{ center: "100%" }],
        series: [
          // These series are in the first grid.
          {
            type: "bar",
            seriesLayoutBy: "row",
            itemStyle: { color: "rgba(0,92,103, 0.8)" },
          },
          {
            type: "bar",
            seriesLayoutBy: "row",
            itemStyle: { color: "rgba(91,166,7,0.8)" }
          },
          {
            type: "bar",
            seriesLayoutBy: "row",
            itemStyle: { color: "rgba(255,94,68, 0.8)" }
          },
        ]
      };
    }
  },
  computed: {
    ...mapGetters({
      storeCashFlow: types.STORE_CASH_FLOW,
    })
  },
  methods: {
   async goToStatements(data) {
      const regex = /(\d{2})([/])(\d{4})/g;
      const dateObject = regex.exec(data.name);

      const month = dateObject[1] - 1;
      const year = parseInt(dateObject[3], 10);
      const lastDay = new Date(year, month, 0).getDate();

      const start = new Date(year, month, 1);
      const end = new Date(year, month, lastDay);
      const rangeDates = {start,end};

      // this.$router.push({
      //   path: '/reports/faturas',
      // });
      }
  }
};
</script>

<style scoped lang="scss">
.title {
  text-align: center;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
</style>
