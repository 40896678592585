<template>
  <section>
    <div slot="header" class="card-header">
      <h1>Fluxo de caixa</h1>
      <el-popover
        placement="bottom-start"
        title="Entenda melhor"
        width="320"
        trigger="hover"
        content="Os valores mostrados a partir do mês atual são apenas uma projeção. Quando os valores restantes são negativos signifca que você recebeu de alguns de seus clientes mais do que eles compraram, então consequentemente alguns de seus clientes tem crédito na sua loja."
      >
        <el-button slot="reference">Entenda o gráfico</el-button>
      </el-popover>
    </div>
    <el-tabs v-model="activeTab">
      <el-tab-pane label="Gráfico de barras" name="bars">
        <div v-loading="loading">
          <BarChart />
        </div>
      </el-tab-pane>
      <el-tab-pane label="Gráfico de linhas" name="lines">
        <LineChart />
      </el-tab-pane>
      <el-tab-pane v-if="!is_neopag_credit_config || authUser.superuser" label="Parametrização do motor de crédito" name="credit-parameter">
        <CreditParameter />
      </el-tab-pane>
    </el-tabs>
  </section>
</template>

<script>
import BarChart from "@/components/CashFlow/BarChart";
import LineChart from "@/components/CashFlow/LineChart";
import CreditParameter from "./CreditParameter.vue";
import * as types from "@/store/types";
import { mapGetters } from "vuex";
export default {
  components: {
    BarChart,
    LineChart,
    CreditParameter
  },
  data() {
    return {
      is_neopag_credit_config: false,
      loading: false,
      activeTab: "bars",
      storesWithFeature: [
        "310bfe3d-5aa3-4941-b1e2-0e5956abc138", // Mocco Center
        "5c6ccc77-a7a8-4255-9fae-467a5098494b", // FARMÁCIA SÃO JOSÉ DE BILAC LTDA ME
        "0439b7f6-37a5-4c5d-acd7-b674e0e0d760", // Stilo Kids
        "5c28787d-d329-40e3-966d-22017d01f8b4", // Central Cred
        "cf644fce-2f2f-4a96-9539-e4e8640ce958", // FARMÁCIA DIA A DIA
        "3434b1a3-51ea-4dc9-89f7-92692d168b11", // MCarvalho Store
        "c9c91877-86fe-4abb-8be4-bca58df91409", // CREDIARIO FASHION
        "32da25a1-bace-4e3f-bbb9-539040584293", // Magazine Couto
        "2a7fdffa-d4a8-4fdb-94af-56fe3e53a814", // Suplerex
        "16ad29b0-99b5-48f1-9a1d-f44df8b50626", // AJN Mercearia e Padaria
        "d0e8cb6e-359c-4a6c-be03-58798c531042", // Cartåo Rede Mercado
        "ea4fdce9-7976-4a59-83bd-0c7e9379d646", // Fenix Surf
        "edc5f5a5-e852-47fd-ba9c-b12b82dc403c", // Reoboe Variedades
        "37626710-ced5-4400-af04-8c989caa2bfe", // neopag store
        "8fc81126-b0d7-4d8a-860b-b966b9022ba2", // BGAZINE
        "ebd0ef45-245a-4177-b6b3-2de726c5c38f"  // ODES CRED
      ]
    };
  },
  computed: {
    ...mapGetters({
      authUser: types.AUTH_USER,
      storeCashFlow: types.STORE_CASH_FLOW,
      storeID: types.AUTH_ACTIVE_STORE,
      config: types.STORE_CONFIG,
    })
  },
  async mounted() {
    this.loading = true;
    try {
      await this.$store.dispatch(types.STORE_CONFIG, this.storeID);
      this.is_neopag_credit_config = this.config.is_neopag_credit_config;
      console.trace(this.config);
      await this.$store.dispatch(types.STORE_CASH_FLOW, {
        storeID: this.storeID
      });
    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
    this.loading = false;
  }
};
</script>

<style scoped lang="scss">
.title {
  text-align: center;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 512px) {
    flex-direction: column;
    justify-content: start;
  }
}
</style>
