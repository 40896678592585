<template>
  <section class="range-picker-container">
    <range-picker-helper
      :value="value"
      :floating="parsedConfig.innerRangeEnabled"
      :labels="labels"
      :min="min"
      :max="max"
    />
    <section class="range-slider">
      <input
        type="range"
        @input="e => handleChange('a', e)"
        :class="markClass"
        :value="pointers.a"
        :step="step"
        :min="min"
        :max="max"
      />
      <input
        v-if="parsedConfig.innerRangeEnabled"
        type="range"
        @input="e => handleChange('b', e)"
        @mousedown="handleClick"
        :class="markClass"
        :value="pointers.b"
        :step="step"
        :min="min"
        :max="max"
      />
    </section>
    <range-picker-ruler :size="parsedConfig.forceRulerSize" />
    <range-picker-label
      :value="value"
      :floating="parsedConfig.innerRangeEnabled"
      :labels="labels"
      :min="min"
      :max="max"
    />
  </section>
</template>

<script>
import RangePickerLabel from './RangePickerLabel.vue'
import RangePickerRuler from './RangePickerRuler.vue'
import RangePickerHelper from './RangePickerHelper.vue'
export default {
  name: 'RangePickerInput',
  components: {
    RangePickerLabel,
    RangePickerRuler,
    RangePickerHelper,
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data() {
    let pointers;
    if (this.config.innerRangeEnabled) {
      pointers = {
        a: this.value.min || this.min,
        b: this.value.max || this.max,
      }
    } else {
      pointers = {
        a: this.value || this.min,
      }
    }
    return {
      pointers,
      parsedConfig: {
        forceRulerSize: 9,
        innerRangeEnabled: false,
        markType: 'circle',
        ...this.config,
      }
    }
  },
  computed: {
    markClass() {
      if (this.parsedConfig.markType === 'circle') {
        return 'circleMark'
      }
      return 'triangleMark'
    }
  },
  watch: {
    value() {
      if (this.parsedConfig.innerRangeEnabled) {
        this.handleValueChange('a', this.value.min || this.min)
        this.handleValueChange('b', this.value.max || this.max)
      } else {
        this.handleValueChange('a', this.value || this.min)
      }
    },
    pointers: {
      deep: true,
      handler: function ({ a, b }) {
        if (this.parsedConfig.innerRangeEnabled) {
          const min = a < b ? a : b
          const max = a > b ? a : b
          this.$emit('input', { min, max })
        } else {
          this.$emit('input', a)
        }
      },
    }
  },
  methods: {
    handlePointers() {
      if (this.parsedConfig.innerRangeEnabled) {
        this.pointers = {
          a: this.value.min || this.min,
          b: this.value.max || this.max,
        }
      } else {
        this.pointers = {
          a: this.value || this.min,
        }
      }
    },
    handleValueChange(reference, value) {
      this.pointers[reference] = value;
    },
    handleChange(reference, event) {
      const value = event.target.value;
      this.pointers[reference] = Number(value);
    },
    handleClick(event) {
      const { a, b } = this.pointers;
      const { offsetX, target } = event
      const { offsetWidth } = target
      const marks = offsetWidth / this.max
      const desiredValue = Math.round(offsetX / marks)
      const distanceA = Math.abs(desiredValue - a)
      const distanceB = Math.abs(desiredValue - b)
      if (distanceA < distanceB) {
        event.preventDefault();
        event.stopPropagation();
        const normalizedValue = desiredValue < this.min ? this.min : desiredValue > this.max ? this.max : desiredValue
        this.pointers.a = Number(normalizedValue)
      } else {
        const normalizedValue = desiredValue > this.max ? this.max : desiredValue > this.max ? this.max : desiredValue
        this.pointers.b = Number(normalizedValue)
      }
    }
  },
  props: {
    min: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      required: true
    },
    step: {
      type: Number,
      default: 1,
    },
    config: {
      type: Object,
      default: () => ({})
    },
    labels: {
      type: Object,
      default: () => ({
        bottom: {
          min: 'Min',
          max: 'Max',
        }
      }),
    },
    value: {
      type: [Number, Object],
      required: true,
      validator: function(value) {
        if (typeof value === 'object') {
          return value.min !== undefined && value.max !== undefined
        }
        return typeof value === 'number'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@mixin circleMark() {
  pointer-events: all;
  position: relative;
  z-index: 1;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 25px;
  height: 25px;
  border: none;
  border-radius: 25px;
  background: #34495e;
  border: 5px solid #5a748e;
}
@mixin triangleMark() {
  z-index: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
	position: relative;
	pointer-events: all;
  width: 25px;
  height: 25px;
  background: #34495e;
}
section.range-slider {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 25px;
  text-align: center;
  input[type="range"] {
    position: absolute;
    -webkit-appearance: none;
    border: none;
    border-radius: 10px/10px;
    background: #bdc3c7;
    left: 0;
    width: 100%;
    outline: none;
    height: 10px;
    margin: 0;
    padding: 0;
  }
  input[type="range"].circleMark::-webkit-slider-thumb {
    @include circleMark();
  }
  input[type="range"].circleMark::-moz-range-thumb {
    @include circleMark();
  }
  input[type="range"].circleMark::-ms-thumb {
    @include circleMark();
  }
  input[type="range"].triangleMark::-webkit-slider-thumb {
    @include triangleMark();
  }
  input[type="range"].triangleMark::-moz-range-thumb {
    @include triangleMark();
  }
  input[type="range"].triangleMark::-ms-thumb {
    @include triangleMark();
  }
  input[type=range]::-moz-range-track {
    position: relative;
    z-index: -1;
    background-color: rgba(0, 0, 0, 1);
    border: 0;
  }
  input[type=range]:last-of-type::-moz-range-track {
    -moz-appearance: none;
    background: none transparent;
    border: 0;
  }
  input[type=range]::-moz-focus-outer {
    border: 0;
  }
}
</style>
