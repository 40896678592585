var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"credit-parameter"},[_c('div',{staticClass:"card"},[_c('el-card',[_c('h2',[_vm._v("Política de Risco")]),_c('h3',[_vm._v("Taxa de aprovação")]),_c('range-picker',{attrs:{"min":1,"max":9,"step":1,"labels":{
          bottom: {
            min: 'aprovar menos',
            max: 'aprovar mais'
          }
        }},model:{value:(_vm.risk),callback:function ($$v) {_vm.risk=$$v},expression:"risk"}}),_c('h3',[_vm._v("Score MÍNIMO: "),_c('strong',[_vm._v(_vm._s(_vm.updatedScoreLimit))])]),_c('br'),(_vm.storesWithFeature.includes(this.storeID))?_c('el-button',{staticClass:"button",attrs:{"disabled":!_vm.currentStore.permissions.settings,"size":"mini","type":"success"},on:{"click":_vm.updateScoreLimit}},[_vm._v("Aplicar")]):_vm._e(),(!_vm.storesWithFeature.includes(this.storeID))?_c('el-tooltip',{staticClass:"header-card",attrs:{"effect":"dark","content":"Fale com nossa equipe para contratar essa funcionalidade.","placement":"bottom"}},[(!_vm.storesWithFeature.includes(this.storeID))?_c('font-awesome-icon',{staticStyle:{"color":"#FF5E44","margin-left":"14px","cursor":"pointer"},attrs:{"icon":"lock","size":"lg"}}):_vm._e()],1):_vm._e()],1)],1),_c('div',{staticClass:"card"},[_c('el-card',[_c('h2',[_vm._v("Política de Crédito")]),_c('h3',[_vm._v("Faixa de limite de crédito")]),_c('br'),_c('h3',[_vm._v("Limite de crédito MÍNIMO atual: "),_c('strong',[_vm._v(_vm._s(_vm.minCredit))])]),_c('h3',[_vm._v("Limite de crédito MÁXIMO atual: "),_c('strong',[_vm._v(_vm._s(_vm.maxCredit))])]),_c('range-picker',{attrs:{"min":0,"max":15000,"step":100,"config":{ innerRangeEnabled: true },"labels":{
          bottom: {
            min: '',
            max: ''
          },
          top: {
            min: _vm.minCredit,
            max: _vm.maxCredit
          }
        }},model:{value:(_vm.credit),callback:function ($$v) {_vm.credit=$$v},expression:"credit"}}),(_vm.storesWithFeature.includes(this.storeID))?_c('el-button',{attrs:{"icon":"lock","disabled":!_vm.currentStore.permissions.settings,"size":"mini","type":"success"},on:{"click":_vm.updateCreditLimits}},[_vm._v("Aplicar")]):_vm._e(),(!_vm.storesWithFeature.includes(this.storeID))?_c('el-tooltip',{staticClass:"header-card",attrs:{"effect":"dark","content":"Fale com nossa equipe para contratar essa funcionalidade.","placement":"bottom"}},[(!_vm.storesWithFeature.includes(this.storeID))?_c('font-awesome-icon',{staticStyle:{"color":"#FF5E44","margin-left":"14px","cursor":"pointer"},attrs:{"icon":"lock","size":"lg"}}):_vm._e()],1):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }