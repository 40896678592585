<template>
  <section>
      <v-chart ref="chart" :options="options" :auto-resize="true" />
  </section>
</template>

<script>
import * as types from "@/store/types";
import { mapGetters } from "vuex";
import ECharts from "vue-echarts/components/ECharts";
import "echarts/lib/chart/line";
import "echarts/lib/component/legendScroll";
import "echarts/lib/component/tooltip";
export default {
  components: {
    "v-chart": ECharts
  },
  data() {
    return {
      options: {},
    };
  },
  watch: {
    storeCashFlow: function(val) {
      const dates = val.map(item => Object.keys(item)[0]);
      const paid_amount = val.map(item => Object.values(item)[0].paid_amount);
      const remaining = val.map(item => Object.values(item)[0].remaining);
      const total = val.map(item => Object.values(item)[0].total);
      this.options = {
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: ["dates", ...dates]
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: dates
        },
        yAxis: {
          type: "value"
        },
        series: [
          {
            name: "Total",
            type: "line",
            stack: "total",
            data: total,
            itemStyle: { color: "rgba(0,92,103, 0.8)" },
            smooth: true
          },
          {
            name: "Pago",
            type: "line",
            stack: "pago",
            data: paid_amount,
            itemStyle: { color: "rgba(91,166,7,0.8)" },
            smooth: true
          },
          {
            name: "Em aberto",
            type: "line",
            stack: "restante",
            data: remaining,
            itemStyle: { color: "rgba(255,94,68, 0.8)" },
            smooth: true
          },
        ]
      };
    }
  },
  computed: {
    ...mapGetters({
      storeCashFlow: types.STORE_CASH_FLOW,
    })
  },
};
</script>
