<template>
  <main class="dashboard">
    <getting-started />
    <dashboard-definitions v-if="authOrigin !== 'app'" @period="handlePeriod" />
    <br />
    <dashboard-charts :period="period" />
  </main>
</template>

<script>
import DashboardCharts from "@/containers/Dashboard/Charts";
import DashboardDefinitions from "@/containers/Dashboard/Definitions";
import GettingStarted from "@/components/Dashboard/GettingStarted";

import * as types from "@/store/types";
import { mapGetters } from "vuex";

export default {
  components: {
    DashboardCharts,
    DashboardDefinitions,
    GettingStarted
  },
  data() {
    return {
      period: "week"
    };
  },
  computed: {
    ...mapGetters({
      authOrigin: types.AUTH_ORIGIN,
      storeUsers: types.STORE_USERS,
      storeID: types.AUTH_ACTIVE_STORE,
      user: types.AUTH_USER
    }),
    showEmptyInstructions() {
      if (this.storeUsers.active === -1) {
        return false;
      }
      return this.storeUsers.active === 0;
    }
  },
  beforeCreate() {
    this.$store.dispatch(types.DASHBOARD_DEFINITIONS);
  },
  methods: {
    handlePeriod(value) {
      this.period = value;
    }
  }
};
</script>

<style lang="scss" scoped>
.dashboard {
  padding: 1rem 1rem 0;
  .welcome-alert {
    margin-bottom: 1rem;
    h2 {
      line-height: 1.5;
      b {
        font-weight: 600;
      }
    }
    .el-button {
      margin-bottom: 1rem;
    }
    @include medium-down {
      h1 {
        font-size: 15px;
      }
      h2 {
        font-size: 13px;
      }
      .el-button {
        margin-top: 1rem;
        margin-right: 1rem;
      }
      .el-button + .el-button {
        margin-left: 0;
        margin-top: 0;
      }
    }
  }
}
</style>
