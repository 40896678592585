<template>
  <div v-loading="loading">
    <h3 class="title">Carteira de crédito</h3>
    <v-chart ref="chart" :options="options" :auto-resize="true" @click="onClick" />
    <el-dialog
      :visible.sync="showModal"
      title="Clientes"
      custom-class="chart_dialog"
      :fullscreen="$isMobile"
      :width="$isMobile ? '100%' : '70%'"
    >
      <el-table
        :data="selectedUsers"
        :default-sort="{
					prop: 'current_remaining_amount',
					order: 'descending'
				}"
        @row-click="onTableClick"
      >
        <el-table-column min-width="230" align="center" prop="user_name" label="Nome" sortable />
        <el-table-column
          align="center"
          min-width="150"
          :formatter="
						date =>
							date && date.last_closed_statement_date
								? formatDate(date.last_closed_statement_date)
								: ''
					"
          prop="last_closed_statement_date"
          label="Última fatura fechada"
          sortable
        />
        <el-table-column
          min-width="150"
          align="center"
          :formatter="
						(row, column) => $n(row.current_remaining_amount, 'currency')
					"
          prop="current_remaining_amount"
          label="Valor da última fatura"
          sortable
        />
        <el-table-column
          min-width="100"
          align="center"
          prop="days_late"
          label="Dias em atraso"
          sortable
        />
        <el-table-column min-width="100" align="center" prop="score" label="Score" sortable />
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import ECharts from "vue-echarts/components/ECharts";
import "echarts/lib/chart/pie";
import "echarts/lib/component/legendScroll";
import "echarts/lib/component/tooltip";
import moment from "moment";

export default {
  components: {
    "v-chart": ECharts
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      options: {},
      selectedUsers: [],
      showModal: false
    };
  },
  computed: mapGetters({
    authOrigin: types.AUTH_ORIGIN,
    creditWallet: types.DASHBOARD_CREDIT_WALLET
  }),
  watch: {
    creditWallet: function(newValue) {
      this.options = {
        tooltip: {
          trigger: "item",
          formatter: function(params) {
            if (params.seriesIndex === 1) {
              return `${params.data.name}: ${new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL"
              }).format(parseFloat(params.data.value))} (${params.percent}%)`;
            } else {
              return `${params.data.name}: ${params.data.value} (${params.percent}%)`;
            }
          }
        },
        color: [
          "rgba(91,166,7,0.8)", //C0
          "rgba(251,211,112, 0.8)", //C1
          "rgba(245,167,119, 0.8)", //C2
          "rgba(243,139,141, 0.8)", //C3
          "rgba(171,138,193, 0.8)", //C4
          "rgba(113,115,169, 0.8)" //C5
        ],
        legend: {
          type: "scroll",
          orient: "horizontal",
          bottom: "bottom",
          data: newValue.map(item => item.label),
          selected: {'Ciclo 5': false}
        },
        series: [
          {
            name: "Carteira de crédito",
            type: "pie",
            radius: [0, "45%"],
            label: {
              show: false
            },
            data: newValue.map(item => ({
              value: item.store_customers.length,
              name: item.label
            })),
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          },
          {
            name: "Carteira de crédito",
            type: "pie",
            radius: ["55%", "75%"],
            showSymbol: false,
            label: {
              show: false
            },
            data: newValue.map(item => ({
              value: item.value,
              name: item.label
            })),
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          }
        ]
      };
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    onClick(data) {
      const { name } = data;
      const selectedData = this.creditWallet.find(item => item.label === name);
      this.selectedUsers = selectedData.store_customers;
      this.showModal = true;
    },
    onTableClick(row) {
      if (this.authOrigin === "app") {
        if (this.client) {
          return NativeAppChannel.postMessage(this.client.user.id);
        }
      }
      this.$router.push({
        name: "Perfil - Histórico de lançamentos",
        params: { clientID: row.user_id }
      });
    }
  }
};
</script>

<style lang="scss">
.echarts {
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 1300px) {
  }
}
.title {
  text-align: center;
}
.el-table {
  cursor: pointer;
}
.chart_dialog {
  .el-dialog__body {
    max-height: 60vh;
    overflow: auto;
  }
}
.empty-state {
  text-align: center;
  margin-top: 20px;
  font-family: "Montserrat";
  color: $preto;
}
</style>
