<template>
  <section
    ref="container"
    :class="{ floating }"
    class="range-picker-helper-container"
    :style="{ '--minLeft': minLeft, '--maxLeft': maxLeft }"
  >
    <div class="min" v-if="labels && labels.top && labels.top.min">
      {{labels.top.min}}
    </div>

    <div class="max" v-if="labels && labels.top && labels.top.max">
      {{labels.top.max}}
    </div>
  </section>
</template>

<script>
export default {
  name: 'RangePickerHelper',
  data() {
    return {
      containerWidth: 0,
      widthWatcher: null
    }
  },
  mounted() {
    this.widthWatcher = setInterval(() => {
      const container = this.$refs.container;
      this.containerWidth = container.getBoundingClientRect().width
    }, 250)
  },
  beforeDestroy() {
    clearInterval(this.widthWatcher)
  },
  computed: {
    minLeft() {
      if (!this.floating) {
        return '0'
      }
      const percentage = this.getLeft(this?.value?.min);
      return `${percentage}px`
    },
    maxLeft() {
      if (!this.floating) {
        return '0'
      }
      const percentage = this.getLeft(this?.value?.max);
      return `${percentage}px`
    }
  },
  methods: {
    getLeft(value) {
      const thumbSize = 25
      const elementWidth = this.containerWidth
      return (((value - this?.min) / (this?.max - this?.min)) * (elementWidth - thumbSize)) + (thumbSize / 2);
    }
  },
  props: {
    labels: {
      type: Object,
      required: true
    },
    floating: {
      type: Boolean,
      required: true
    },
    value: {
      type: [Number, Object],
      required: true
    },
    min: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      required: true,
    }
  }
}
</script>
<style lang="scss" scoped>
.range-picker-helper-container {
  margin-bottom: 5px;
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 0 12.5px;
  &.floating {
    position: relative;
    .min, .max {
      position: relative;
      margin-right: 0;
    }
    .min {
      left: var(--minLeft);
      margin-left: -62.5px;
    }
    .max {
      left: var(--maxLeft);
      margin-left: -100px;
    }
  }
  .min, .max {
    width: 100px;
    text-align: center;
    color: #34495e;
    font-weight: bold;
    font-size: 14px;
  }
  .min {
    margin-left: -50px;
  }
  .max {
    margin-right: -50px;
    margin-left: auto;
  }
}
</style>
