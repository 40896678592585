<template>
  <ul class="range-picker-ruler">
    <li ref="items" v-for="n in size" :key="n" />
  </ul>
</template>

<script>
export default {
  name: 'RangePickerRuler',
  data() {
    return {
      interval: null
    }
  },
  computed: {
    width() {
      return `${100/this.size}%`;
    }
  },
  props: {
    size: {
      type: Number,
      default: 9,
    },
  },
  mounted() {
    this.interval = setInterval(this.setItemsPosition, 250)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    setItemsPosition() {
      const items = this.$refs.items;

      items.forEach((item, index) => {
        const elementWidth = item.getBoundingClientRect().width
        const thumbSize = 25
        const formulaResult = (((index) / (this.size - 1)) * (elementWidth - thumbSize)) + (thumbSize / 2)
        item.style.setProperty('--left', `${formulaResult}px`)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.range-picker-ruler {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  list-style-type: none;
  li {
    flex: 1;
    height: 25px;
    display: flex;
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 5px;
      height: 25px;
      border-radius: 25px/25px;
      background: #5b758f;
      top: 0;
      position: absolute;
      left: var(--left);
      margin-left: -2.5px;
    }
  }
}
</style>
