<template>
	<div v-loading="loading">

		<v-chart
			v-if="saleHistory.length > 0"
			ref="chart"
			:options="options"
			:auto-resize="true"
			@click="onClick"
		/>
		<p v-else class="empty-state">Sem dados :/</p>
		<el-dialog
			:visible.sync="showModal"
			:fullscreen="$isMobile ? true : false"
			class="sale_dialog"
			custom-class="sale_dialog"
		>
		<h2 class="dialog-title">Vendas</h2>
			<el-table :data="selectedSales">

				<el-table-column
					:formatter="row => formatDate(row.sale_date)"
					prop="sale_date"
					label="Data"
					min-width="150"
					align="center"
				/>
				<el-table-column :formatter="row => row.operator.name" prop="operator" label="Operador" min-width="150" align="center" />
				<el-table-column
					:formatter="row => $n(row.amount, 'currency')"
					align="center"
					prop="amount"
					label="Valor"
					min-width="120"
				/>
			</el-table>
		</el-dialog>
      <h5 class="title">(ano - semana)</h5>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import ECharts from "vue-echarts/components/ECharts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/legend";
import "echarts/lib/component/tooltip";
import moment from "moment";

moment.locale("pt-BR");

export default {
	components: {
		"v-chart": ECharts
	},
	props: {
		period: {
			type: String,
			default: "week"
		},
		loading: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			options: {},
			selectedSales: [],
			groupedValues: {},
			showModal: false
		};
	},
	computed: mapGetters({
		saleHistory: types.DASHBOARD_SALE_HISTORY
	}),
	watch: {
		saleHistory: function(newValue) {
			const groupedValues = this.groupData(newValue, this.period);
			const source =
				this.period === "week"
					? Object.values(groupedValues).reverse()
					: Object.values(groupedValues);
			this.groupedValues = groupedValues;
			this.options = {
				tooltip: {
					trigger: "item",
					formatter: function(params) {
						if (params.data) {
							return `${params.data[0]} ${new Intl.NumberFormat("pt-BR", {
								style: "currency",
								currency: "BRL"
							}).format(parseFloat(params.data[1]))}`;
						}
						return "Legenda indisponível";
					}
				},
				legend: {
					show: false,
					type: "plain",
					orient: "horizontal",
					bottom: "bottom"
				},
				color: ["rgba(108, 167, 84, 0.5)"],
				dataset: {
					source: [...source.map(item => [`${item.date}`, item.value])]
				},
				xAxis: { type: "category" },
				// Declare Y axis, which is a value axis.
				yAxis: { type: "value" },
				series: [
					{
						type: "bar",
						name: "Histórico de vendas"
					}
				]
			};
		},
		period: function(newValue) {
			const groupedValues = this.groupData(this.saleHistory, newValue);
			const source =
				this.period === "week"
					? Object.values(groupedValues).reverse()
					: Object.values(groupedValues);
			this.groupedValues = groupedValues;
			this.options = {
				tooltip: {
					trigger: "item",
					formatter: function(params) {
						return `${params.data[0]} ${new Intl.NumberFormat("pt-BR", {
							style: "currency",
							currency: "BRL"
						}).format(parseFloat(params.data[1]))}`;
					}
				},
				legend: {
					show: false,
					type: "plain",
					orient: "horizontal",
					bottom: "bottom"
				},
				color: ["rgba(108, 167, 84, 0.5)"],
				dataset: {
					source: [...source.map(item => [`${item.date}`, item.value])]
				},
				xAxis: { type: "category" },
				// Declare Y axis, which is a value axis.
				yAxis: { type: "value" },
				series: [
					{
						type: "bar",
						name: "Histórico de vendas",
						dimensions: [{ displayName: "Teste historico" }]
					}
				]
			};
		}
	},
	methods: {
		formatDate(date) {
			return moment(date).format("DD/MM/YYYY, HH:mm");
		},
		onClick(data) {
			const date = data.value[0].split(" ")[0];
			if (this.period === "week") {
				const sales = Object.values(this.groupedValues).find(
					item => item.date === date
				);
				this.selectedSales = sales.entry;
			} else {
				this.selectedSales = this.groupedValues[date].entry;
			}
			this.showModal = true;
		},
		groupData(data, type) {
			let groupedValues = {};
			switch (type) {
				case "day":
					data.forEach(element => {
						const date = moment(element.sale_date).format("DD-MM-YYYY");
						if (groupedValues[date]) {
							groupedValues[date].value =
								groupedValues[date].value + element.amount;
							groupedValues[date].entry.push(element);
						} else {
							groupedValues = {
								...groupedValues,
								[date]: {
									date,
									value: element.amount,
									entry: [element]
								}
							};
						}
					});
					break;
				case "week":
					data.forEach(element => {
            // [kenzo][10-08-2021] implementacao para ordenar grafico de historico de vendas e recebimentos
						const date = moment(element.sale_date).format("GGGG - ww");
						const week = moment(element.sale_date).format("GGGG - ww")
						if (groupedValues[week]) {
							groupedValues[week].value =
								groupedValues[week].value + element.amount;
							groupedValues[week].entry.push(element);
						} else {
							groupedValues = {
								...groupedValues,
								[week]: {
									date,
									value: element.amount,
									entry: [element]
								}
							};
						}
					});
					break;
				case "month":
					data.forEach(element => {
						const date = moment(element.sale_date)
							.format("MMMM-YYYY")
							.toUpperCase();
						if (groupedValues[date]) {
							groupedValues[date].value =
								groupedValues[date].value + element.amount;
							groupedValues[date].entry.push(element);
						} else {
							groupedValues = {
								...groupedValues,
								[date]: {
									date,
									value: element.amount,
									entry: [element]
								}
							};
						}
					});
					break;
				case "year":
					data.forEach(element => {
						const date = moment(element.sale_date)
							.format("YYYY")
							.toUpperCase();
						if (groupedValues[date]) {
							groupedValues[date].value =
								groupedValues[date].value + element.amount;
							groupedValues[date].entry.push(element);
						} else {
							groupedValues = {
								...groupedValues,
								[date]: {
									date,
									value: element.amount,
									entry: [element]
								}
							};
						}
					});
					break;
				default:
					groupedValues = {};
			}
			return groupedValues;
		}
	}
};
</script>

<style lang="scss" scoped>
.echarts {
	width: 100%;
}
.title {
	text-align: center;
}
.empty-state {
	text-align: center;
	margin-top: 20px;
	font-family: "Montserrat";
	color: $preto;
}
.dialog-title {
	font-weight: bold;
	color: $laranja;
}
</style>

<style lang="scss">
.sale_dialog {
	.el-dialog__body {
		max-height: 60vh;
		overflow: auto;
	}
}
</style>
