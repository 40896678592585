<template id="my-template">
  <el-card v-loading="loading" class="timeline-card">
    <h3 style="text-align: center; padding-bottom: 10px;">Últimos eventos</h3>
    <div class="container">
      <div class="timeline">
        <div class="timeline--wrapper">
          <div v-for="item in timeline" :key="item.id" class="article">
            <span class="dot" />
            <p class="article-date">{{ item.date | formatDate }}</p>
            <h3 class="article-description">{{ item.description }}</h3>
            <p v-if="item.store_customer && item.store_customer.user" class="article-name">
              Cliente:
              <span
                class="user-name"
                @click="handleNavigation(item)"
              >{{ item.store_customer.user.name }}</span>
            </p>
            <p v-if="item.amount > 0" class="article-name">Valor: {{ $n(item.amount, 'currency') }}</p>
          </div>
        </div>
      </div>
      <el-button size="mini" @click="fetchMoreActivities">Carregar mais</el-button>
    </div>
  </el-card>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import * as types from "@/store/types";

moment.locale("pt-BR");

export default {
  data() {
    return {
      initialPage: 10,
      loading: false
    };
  },
  filters: {
    formatDate(date) {
      return moment(date).fromNow();
    }
  },
  computed: {
    ...mapGetters({
      authOrigin: types.AUTH_ORIGIN,
      timeline: types.STORE_ACTIVITIES_TIMELINE,
      storeID: types.AUTH_ACTIVE_STORE
    })
  },
  async mounted() {
    await this.getTimeline();
  },
  methods: {
    handleNavigation(item) {
      if (this.authOrigin === "app") {
        return NativeAppChannel.postMessage(item.store_customer.user.id);
      }
      this.$router.push({
        name: "Perfil - Histórico de lançamentos",
        params: { clientID: item.store_customer.user.id }
      });
    },
    countAllArticles() {
      var count = 0;
      for (var date in this.searchedArticles) {
        count += this.searchedArticles[date].length;
      }
      return count;
    },
    getTimeline() {
      this.$store
        .dispatch(types.STORE_ACTIVITIES_TIMELINE, {
          storeID: this.storeID,
          page: 1,
          page_size: this.initialPage
        })
        .catch(error => {
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({ title: error.message });
        });
    },
    async fetchMoreActivities() {
      this.loading = true;
      this.initialPage += 10;
      await this.$store
        .dispatch(types.STORE_ACTIVITIES_TIMELINE, {
          storeID: this.storeID,
          page: 1,
          page_size: this.initialPage
        })
        .catch(error => {
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({ title: error.message });
        });
      this.loading = false;
    }
  }
};
</script>

<style lang="scss">
.timeline-card {
  max-height: 1118px;
  @media screen and (max-width: 768px) {
    max-height: 514px;
  }
  overflow-x: auto;
  .el-card__body {
    overflow-x: auto;
    max-height: 1118px;
    @media screen and (max-width: 768px) {
      max-height: 514px;
    }
  }
  .el-button--mini {
    margin-top: 20px;
    align-self: center;
  }
}
.article-name {
  .user-name:hover {
    color: $laranja;
    cursor: pointer;
  }
}
</style>



<style scoped>
.container {
  margin: 0 auto;
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
}

.timeline {
  position: relative;
}
.timeline--wrapper {
  border-left: 1px solid #6c737c;
}

.date {
  display: inline-block;
  border: 1px solid #6c737c;
  border-radius: 5px;
  padding: 5px;
  position: relative;
  left: 15px;
  margin: 15px 0;
}

&:before {
  content: "";
  position: absolute;
  top: 50%;
  left: -16px;
  border: 1px solid #6c737c;
  width: 14px;
}

.article {
  position: relative;
  left: 20px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 10px;
  margin: 0 0 10px 0;
  background-color: white;
  /* height: 100px; */
  max-width: 93%;
}

&:hover {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.4);
}

.article-description {
  color: #ff5e44;
  text-decoration: none;
  margin: 10px 0 0 0;
}

.article-date {
  font-weight: 300;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  color: #6c737c;
}

.article-name {
  font-family: "Montserrat", sans-serif;
  color: #6c737c;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}

.dot {
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ff5e44;
  left: -25.5px;
  top: calc(50% - 5px);
}
</style>
